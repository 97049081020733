@import "~bootstrap/scss/bootstrap";
$defaultRed: #E2002D;
$defaultBlue: #008AE0;
$defaultNavyBlue: #031332;
$defaultGrey: #666;
$headerFont: 'Lucida Grande', sans-serif;

body {
    color:$defaultGrey;
}
h1, h2, h3, h4 {
    font-family: $headerFont;
}
form {
    padding-bottom: 50px;
}
.landing {
    .btn-access-red,.btn-access-red:hover {
        color: #fff;
        background-color: $defaultRed;
        border-color: $defaultRed;
    }
    h3 {
        font-size:1.5rem;
    }
}